import { FC, useCallback } from "react";
import Script from "next/script";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Icon from "apps/website/components/base/Icon/Icon";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { slugify } from "apps/website/utils/content/text";
import { TrustpilotRating } from "apps/website/api/Trustpilot";

import TrustPilotStars from "../../misc/TrustPilotStars";

import { Layout } from "./Review.map";

export type ReviewStarStyle = "default" | "trustpilot";

export interface IReview {
  theme?: Theme;
  component?: string;
  title: string;
  titleDisplay?: Display;
  hideTitle?: boolean;
  body?: string;
  bodyDisplay?: Display;
  author?: string;
  authorLogo?: IImageProps;
  hasShadow?: boolean;
  stars?: number;
  hideStars?: boolean;
  starStyle?: ReviewStarStyle;
  className?: string;
  layout?: Layout;
}

const Review: FC<IReview> = ({
  title,
  titleDisplay = "title",
  hideTitle = false,
  body,
  bodyDisplay = "default",
  author,
  authorLogo,
  stars,
  hideStars = false,
  component = "Review",
  theme = "default",
  hasShadow = false,
  layout = "default",
  starStyle = "default",
  className,
}) => {

  const Stars = useCallback(() => ((stars && !hideStars) ? (
    <>
      <Spacer size="lg" />
      <div className="flex justify-center">
        <>
          { starStyle === "default" ? (
            <>
          { Array.from(Array(stars)).map((star, index) => <Icon key={`${star}-${index}`} icon="star" size="small" />) /* eslint-disable-line */ }
            </>
          ) : (
            <div className="w-32 lg:w-40">
              <TrustPilotStars rating={stars as TrustpilotRating} />
            </div>
          ) }
        </>
      </div>
    </>
  ) : <></>), [ stars, hideStars ]);

  const Author = useCallback(() => (author ? (
    <>
      <Spacer size="md" />
      <Text size={legacySizeCollectionMap.body2Xs} align="center">{ author }</Text>
    </>
  ) : <></>), [ author ]);

  const Body = useCallback(() => (body ? (
    <Text align="center" size={legacySizeCollectionMap.base} display={bodyDisplay}>{ body }</Text>
  ) : <></>), [ body, bodyDisplay ]);

  const Title = useCallback(() => (!hideTitle ? (
    <>
      { layout === "secondary" && <Spacer size="md" /> }
      <Text size={legacySizeCollectionMap.titleSm} display={titleDisplay} align="center">{ title }</Text>
    </>
  ) : <></>), [ hideTitle, title, titleDisplay, layout ]);

  return (
    <>
      <Script
        id={`jsonld-review-${slugify(title)}`}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify({
          "@type": "Review",
          reviewBody: body,
          reviewRating: {
            "@type": "Rating",
            ratingValue: 5,
            worstRating: 1,
            bestRating: stars,
          },
        }),
        }}
      />
      <div data-component={component} className={`flex justify-center items-center flex-col px-4 ${className} ${hasShadow && ("shadow-xl")} ${themeRootClassMap[theme]}`} data-theme={theme}>
        <div className="max-w-xl flex flex-col justify-center items-center">
          { (layout === "default" && authorLogo?.src) && (
            <>
              <Spacer size="md" />
              <div className="max-w-[140px]">
                <Image image={authorLogo} alt={title} />
              </div>
            </>
          ) }
          { layout === "default" && <Stars /> }
          <Spacer size="md" />
          { title && (
            <Title />
          ) }
          { body && (
            <Body />
          ) }
          { author && (
            <Author />
          ) }
          { layout === "secondary" && <Stars /> }
          { (layout === "secondary" && authorLogo?.src) && (
            <>
              <Spacer size="md" />
              <div className="max-w-[140px]">
                <Image image={authorLogo} alt={title} />
              </div>
            </>
          ) }
          <Spacer size="lg" />
        </div>
      </div>
    </>
  );
};

export default Review;
