"use client";

import { FC } from "react";

import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import CondensedImageCardCarousel, {
  ICondensedImageCardCarouselProps,
} from "apps/website/components/feature/Carousel/CondensedImageCardCarousel/CondensedImageCardCarousel";

export interface ISectionCondensedImageCardCarousel extends ICondensedImageCardCarouselProps {
  theme?: Theme;
}

const SectionCondensedImageCardCarousel: FC<ISectionCondensedImageCardCarousel> = ({ items, theme = "default" }) => (
  <Section component="SectionCondensedImageCardCarousel" data-theme={theme} className={themeRootClassMap[theme]} size={theme === "default" ? "none" : "xl"}>
    <Container>
      <CondensedImageCardCarousel items={items} />
    </Container>
  </Section>
);

export default SectionCondensedImageCardCarousel;
